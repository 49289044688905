import React, { useEffect, useState } from 'react';

const ClientApp = React.lazy(() => import('./components/ClientApp'));

function App() {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (isClient) {
    return <ClientApp />;
  }

  return null;
}

export default App;
