import { hydrateRoot } from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const rootElement = document.getElementById('root');

if (rootElement) {
  hydrateRoot(rootElement, <App />);
}

reportWebVitals();
